* {
    font-family: 'Nunito', sans-serif;
}

.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
}

.question-container {
    background-color: #f0f0f0;
    padding: 2rem;
    opacity: 1;
    visibility: visible;
    transition: opacity 0s;
}

.question-container.hidden {
    opacity: 0;
    visibility: hidden;
}

.question-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.buttons-container {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

button {
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.yes-button {
    background-color: #4CAF50;
    color: white;
    max-width: 90vw;
    max-height: 90vh;
}

.no-button {
    background-color: #f44336;
    color: white;
}

.yes-button:hover {
    background-color: #45a049;
    transform: scale(1.1);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.no-button:hover {
    background-color: #da190b;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease;
    text-align: center;
}

.success-message.visible {
    opacity: 1;
    visibility: visible;
}

.heart-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 2rem;
}

h1 {
    font-size: 2rem;
    margin: 0;
    font-weight: 700;
}